<template>
  <div id="law">
    <CardHeader ref="header" :node="headerTitle" />
    <div id="content-scroll" class="content-scroll">
      <div class="law">
        <div class="logo-law">
          <img src="~@/assets/image/guideline.svg" alt="" />
        </div>
        <div class="content">
          <div class="content__item">
            <div class="content__item-label f-w6 text-left">◆サイト名</div>
            <p class="content__item-link f-w3 text-left">PreGo（プレゴ）</p>
          </div>
          <div class="content__item">
            <div class="content__item-label f-w6 text-left">
              ◆販売業者（事業者）の名称
            </div>
            <p class="content__item-link f-w3 text-left">
              株式会社 エフオーピーシー
            </p>
          </div>
          <div class="content__item">
            <div class="content__item-label f-w6 text-left">◆運営責任者</div>
            <p class="content__item-link f-w3 text-left">
              <span>代表取締役 </span><span>森 </span> <span>修平</span>
            </p>
          </div>
          <div class="content__item">
            <div class="content__item-label f-w6 text-left">◆所在地</div>
            <p class="content__item-link f-w3 text-left mb-0">〒151-0051</p>
            <p class="content__item-link f-w3 text-left">
              東京都渋谷区千駄ヶ谷四丁目29番12号 北参道ダイヤモンドパレス508
            </p>
          </div>
          <div class="content__item">
            <div class="content__item-label f-w6 text-left">お問い合わせ先</div>
            <p class="content__item-label f-w3 text-left mb-0">
              <!--              <a-->
              <!--                href="info@prego.golf"-->
              <!--                style="text-decoration: none; pointer-events: none"-->
              <!--                >info@prego.golf</a-->
              <!--              >-->
              <span class="link" @click="showForm()"
                >https://forms.gle/ppmQexe9Ro2JYEcw8</span
              >
            </p>
            <p class="content__item-link f-w3 text-left">
              連絡先電話番号については、上記のお問い合わせフォームでご請求いただければ、遅滞なく開示いたします。
            </p>
          </div>
          <div class="content__item">
            <div class="content__item-label f-w6 text-left">◆販売価格</div>
            <p class="content__item-link f-w3 text-left mb-0">
              購入手続きの際に画面に表示されます。
            </p>
            <p class="content__item-link f-w3 text-left mb-0">
              会員登録は原則無料です。
            </p>
            <p class="content__item-link f-w3 text-left mb-0">
              会員登録後に、お客様の情報を記入した上で、サブスクリプションプラン購入の登録を行
              って頂きます。
            </p>
            <p class="content__item-link f-w3 text-left mb-0">
              現地集合プランと特定個人をSuperオファーするプランを選択される場合は有償になります。
            </p>
            <p class="content__item-link f-w3 text-left mb-0">
              具体的な消費ポイントについては、場所やSuperオファー相手によって変動しますので最終的な消費ポイントをご確認ください。1ポイントは1.2円です。
            </p>
            <p class="content__item-link f-w3 text-left mb-0">
              ※なお、販売価格以外に、インターネット接続料金その他の電気通信回線の通信に関する費用等はお客様にて別途ご用意いただく必要があります（金額は、お客様が契約した各事業者が定める金額となります）。
            </p>
          </div>
          <div class="content__item">
            <div class="content__item-label f-w6 text-left">
              ◆商品等の引渡時期
            </div>
            <p class="content__item-link f-w3 text-left">
              デジタルコンテンツやウェブサービスは、購入手続き完了確認後、直ちにご利用いただけます。
            </p>
          </div>
          <div class="content__item">
            <div class="content__item-label f-w6 text-left">
              ◆商品以外の代金について
            </div>
            <p class="content__item-link f-w3 text-left">なし</p>
          </div>
          <div class="content__item">
            <div class="content__item-label f-w6 text-left">◆お支払方法</div>
            <p class="content__item-link f-w3 text-left mb-0">
              クレジットカード決済のみ
            </p>
            <p class="content__item-link f-w3 text-left">
              代金の支払時期は、ご利用のクレジットカードの締め日や契約内容により異なります。ご利用されるカード会社までお問い合わせください。
            </p>
          </div>
          <div class="content__item">
            <div class="content__item-label f-w6 text-left">
              ◆キャンセル等に関する特約
            </div>
            <p class="content__item-link f-w3 text-left mb-0">
              本サイトで販売するポイント、サービスについては、購入手続完了後のお客様のご都合によるキャンセル（中途退会による場合を含みます）はできません。なお、本サービスに債務不履行がある場合は、利用規約の定めに従って対応いたします。
            </p>
            <p class="content__item-link f-w3 text-left">
              動作環境及び必要なスペック は、購入ページで表示しています。<br />
              特別な販売条件または提供条件がある場合については、各サービスの購入ページにて条件を表示いたします。
            </p>
          </div>
        </div>
      </div>
    </div>
    <card-footer ref="footer" />
  </div>
</template>
<script>
import CardFooter from "@/components/CardFooter";
import CardHeader from "@/components/CardHeader";

export default {
  name: "Law",
  components: {
    "card-footer": CardFooter,
    CardHeader
  },
  metaInfo() {
    return {
      title: "特定商取引法に基づく表記",
      titleTemplate: "%s | PreGo"
    };
  },
  data() {
    return {
      headerTitle: {
        text: "PreGo 特定商取引法に基づく表記",
        isShowLogo: false,
        isShowTitle: true
      }
    };
  },
  methods: {
    showForm() {
      window.open("https://forms.gle/ppmQexe9Ro2JYEcw8");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/law.scss";

.content-scroll {
  // overflow-y: scroll;
  padding-top: 40px;
  padding-bottom: 120px;
  overflow-x: hidden;
  // height: calc(100vh - 108px);
}

.link {
  color: #0cc8f2;
  cursor: pointer;
}

@media screen and (min-width: 1200px) {
  .content-scroll {
    padding-top: 62px;
    // height: calc(100vh - 152px);
  }
}
</style>
